(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
import { css } from "styled-components";
import markdownIcon from "../../../assets/i/lexical/markdown.svg";
import justifyIcon from "../../../assets/i/lexical/justify.svg";
import textRightIcon from "../../../assets/i/lexical/text-right.svg";
import textCenterIcon from "../../../assets/i/lexical/text-center.svg";
import textLeftIcon from "../../../assets/i/lexical/text-left.svg";
import linkIcon from "../../../assets/i/lexical/link.svg";
import codeIcon from "../../../assets/i/lexical/code.svg";
import typeStrikethroughIcon from "../../../assets/i/lexical/type-strikethrough.svg";
import typeUnderlineIcon from "../../../assets/i/lexical/type-underline.svg";
import typeItalicIcon from "../../../assets/i/lexical/type-italic.svg";
import typeBoldIcon from "../../../assets/i/lexical/type-bold.svg";
import chatSquareQuouteIcon from "../../../assets/i/lexical/chat-square-quote.svg";
import listOlIcon from "../../../assets/i/lexical/list-ol.svg";
import listUlIcon from "../../../assets/i/lexical/list-ul.svg";
import typeH3Icon from "../../../assets/i/lexical/type-h3.svg";
import textParagraphIcon from "../../../assets/i/lexical/text-paragraph.svg";
import arrowClockWiseIcon from "../../../assets/i/lexical/arrow-clockwise.svg";
import arrowCounterClockwiseIcon from "../../../assets/i/lexical/arrow-counterclockwise.svg";
import pencilFillIcon from "../../../assets/i/lexical/pencil-fill.svg";
import typeH4Icon from "../../../assets/i/lexical/type-h4.svg";
import chevronDownIcon from "../../../assets/i/lexical/chevron-down.svg";
import micIcon from "../../../assets/i/lexical/mic.svg";
export const lexicalCss = css`
  ${_ref => {
  let {
    theme
  } = _ref;
  return css`
    .actions {
      position: absolute;
      text-align: right;
      padding: 10px;
      bottom: 0;
      right: 0;

      i {
        background-size: contain;
        display: inline-block;
        height: 15px;
        width: 15px;
        vertical-align: -0.25em;
      }
    }

    .action-button {
      border: 0;
      padding: 8px 12px;
      position: relative;
      margin-left: 5px;
      border-radius: 15px;
      color: ${theme.colors.text.primary};
      display: inline-block;
      cursor: pointer;
      ${theme.name.includes("dark") && `filter: invert(1)`};

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #000;
      }
    }

    button.action-button:disabled {
      opacity: 0.6;
      background: ${theme.colors.text.disabled};
      cursor: not-allowed;
    }

    .other h2 {
      font-size: 18px;
      color: ${theme.colors.text.primary};
      margin-bottom: 7px;
    }

    .other a {
      color: ${theme.colors.text.primary};
      text-decoration: underline;
      font-size: 14px;
    }

    .other ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    .ltr {
      text-align: left;
    }

    .rtl {
      text-align: right;
    }

    .editor-container {
      margin: 0 auto;
      border-radius: 2px;
      color: ${theme.colors.text.primary};
      position: relative;
      line-height: 20px;
      font-weight: 400;
      text-align: left;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .editor-inner {
      background: #ededed;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      position: relative;
    }

    .editor-input {
      min-height: 150px;
      resize: none;
      font-size: 15px;
      position: relative;
      tab-size: 1;
      outline: 0;
      padding: 15px 10px;
      caret-color: #444;

      ol,
      ul {
        padding-left: 1.5em;

        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }

      ol {
        list-style: nu;
      }
    }

    .editor-placeholder {
      color: #999;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      top: 15px;
      left: 10px;
      font-size: 15px;
      user-select: none;
      display: inline-block;
      pointer-events: none;
    }

    .editor-text-bold {
      font-weight: bold;
    }

    .editor-text-italic {
      font-style: italic;
    }

    .editor-text-underline {
      text-decoration: underline;
    }

    .editor-text-strikethrough {
      text-decoration: line-through;
    }

    .editor-text-underlineStrikethrough {
      text-decoration: underline line-through;
    }

    .editor-text-code {
      background-color: ${theme.colors.text.primary};
      padding: 1px 0.25rem;
      font-family: Menlo, Consolas, Monaco, monospace;
      font-size: 94%;
    }

    .editor-link {
      color: ${theme.colors.primary.main};
      text-decoration: none;
    }

    .editor-code {
      background-color: ${theme.colors.text.primary};
      font-family: Menlo, Consolas, Monaco, monospace;
      display: block;
      padding: 8px 8px 8px 52px;
      line-height: 1.53;
      font-size: 13px;
      margin: 0;
      margin-top: 8px;
      margin-bottom: 8px;
      tab-size: 2;
      white-space: pre;
      overflow-x: auto;
      position: relative;
    }

    .editor-code:before {
      content: attr(data-gutter);
      position: absolute;
      background-color: #eee;
      left: 0;
      top: 0;
      border-right: 1px solid #ccc;
      padding: 8px;
      color: ${theme.colors.text.primary};
      white-space: pre-wrap;
      text-align: right;
      min-width: 25px;
    }

    .editor-code:after {
      content: attr(data-highlight-language);
      top: 2%;
      right: 5px;
      padding: 3px;
      font-size: 10px;
      text-transform: uppercase;
      position: absolute;
      color: rgba(0, 0, 0, 0.5);
    }

    .editor-tokenComment {
      color: slategray;
    }

    .editor-tokenPunctuation {
      color: #999;
    }

    .editor-tokenProperty {
      color: #905;
    }

    .editor-tokenSelector {
      color: #690;
    }

    .editor-tokenOperator {
      color: #9a6e3a;
    }

    .editor-tokenAttr {
      color: #07a;
    }

    .editor-tokenVariable {
      color: #e90;
    }

    .editor-tokenFunction {
      color: #dd4a68;
    }

    .editor-paragraph {
      margin: 0;
      margin-bottom: 8px;
      position: relative;
    }

    .editor-paragraph:last-child {
      margin-bottom: 0;
    }

    .editor-heading-h1 {
      font-size: 24px;
      color: rgb(5, 5, 5);
      font-weight: 400;
      margin: 0;
      margin-bottom: 12px;
      padding: 0;
    }

    .editor-heading-h2 {
      font-size: 15px;
      color: rgb(101, 103, 107);
      font-weight: 700;
      margin: 0;
      margin-top: 10px;
      padding: 0;
      text-transform: uppercase;
    }

    .editor-quote {
      margin: 0;
      margin-left: 20px;
      font-size: 15px;
      color: rgb(101, 103, 107);
      border-left-color: rgb(206, 208, 212);
      border-left-width: 4px;
      border-left-style: solid;
      padding-left: 16px;
    }

    .editor-list-ol {
      padding: 0;
      margin: 0;
    }

    .editor-list-ul {
      padding: 0;
      margin: 0;
    }

    .editor-listitem {
      margin: 8px 32px 8px 32px;
    }

    .editor-nested-listitem {
      list-style-type: none;
    }

    pre::-webkit-scrollbar {
      background: transparent;
      width: 10px;
    }

    pre::-webkit-scrollbar-thumb {
      background: #999;
    }

    .toolbar {
      display: flex;
      margin-bottom: 1px;
      background: #ededed;
      padding: 4px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      vertical-align: middle;
    }

    .toolbar button.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;
      vertical-align: middle;
      ${theme.name.includes("dark") && `filter: invert(1)`}
    }

    .toolbar button.toolbar-item:disabled {
      cursor: not-allowed;
    }

    .toolbar button.toolbar-item.spaced {
      margin-right: 2px;
    }

    .toolbar button.toolbar-item i.format {
      background-size: contain;
      height: 18px;
      width: 18px;
      margin-top: 2px;
      vertical-align: -0.25em;
      display: flex;
      opacity: 0.6;
    }

    .toolbar button.toolbar-item:disabled i.format {
      opacity: 0.2;
    }

    .toolbar button.toolbar-item.active {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .toolbar button.toolbar-item.active i {
      opacity: 1;
    }

    .toolbar .toolbar-item:hover:not([disabled]) {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .toolbar .divider {
      width: 1px;
      background-color: ${theme.colors.grey.main};
      margin: 0 4px;
    }

    .toolbar select.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 70px;
      font-size: 14px;
      color: ${theme.colors.text.primary};
      text-overflow: ellipsis;
    }

    .toolbar select.code-language {
      text-transform: capitalize;
      width: 130px;
    }

    .toolbar .toolbar-item .text {
      display: flex;
      line-height: 20px;
      vertical-align: middle;
      font-size: 14px;
      color: #777;
      text-overflow: ellipsis;
      width: 70px;
      overflow: hidden;
      height: 20px;
      text-align: left;
    }

    .toolbar .toolbar-item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 8px;
      line-height: 16px;
      background-size: contain;
    }

    .toolbar i.chevron-down {
      margin-top: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      user-select: none;
    }

    .toolbar i.chevron-down.inside {
      width: 16px;
      height: 16px;
      display: flex;
      margin-left: -25px;
      margin-top: 11px;
      margin-right: 10px;
      pointer-events: none;
    }

    i.chevron-down {
      background-color: transparent;
      background-size: contain;
      display: inline-block;
      height: 8px;
      width: 8px;
      background-image: url("${chevronDownIcon}");
    }

    #block-controls button:hover {
      background-color: #efefef;
    }

    #block-controls button:focus-visible {
      border-color: blue;
    }

    #block-controls span.block-type {
      background-size: contain;
      display: block;
      width: 18px;
      height: 18px;
      margin: 2px;
    }

    #block-controls span.block-type.paragraph {
      background-image: url("${textParagraphIcon}");
    }

    #block-controls span.block-type.h3 {
      background-image: url("${typeH3Icon}");
    }

    #block-controls span.block-type.h4 {
      background-image: url("${typeH4Icon}");
    }

    #block-controls span.block-type.quote {
      background-image: url("${chatSquareQuouteIcon}");
    }

    #block-controls span.block-type.ul {
      background-image: url("${listUlIcon}");
    }

    #block-controls span.block-type.ol {
      background-image: url("${listOlIcon}");
    }

    #block-controls span.block-type.code {
      background-image: url("${codeIcon}");
    }

    .link-editor {
      position: absolute;
      z-index: 100;
      top: -10000px;
      left: -10000px;
      margin-top: -6px;
      max-width: 300px;
      width: 100%;
      opacity: 0;
      background-color: #fff;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      transition: opacity 0.5s;

      .link-input {
        display: block;
        width: calc(100% - 24px);
        box-sizing: border-box;
        margin: 8px 12px;
        padding: 8px 12px;
        border-radius: 15px;
        background-color: #eee;
        font-size: 15px;
        color: rgb(5, 5, 5);
        border: 0;
        outline: 0;
        position: relative;
        font-family: inherit;
      }

      div.link-edit {
        background-image: url("${pencilFillIcon}");
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        width: 35px;
        vertical-align: -0.25em;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
      }

      .link-input a {
        color: rgb(33, 111, 219);
        text-decoration: none;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 30px;
        text-overflow: ellipsis;

        :hover {
          text-decoration: underline;
        }
      }

      .button {
        width: 20px;
        height: 20px;
        display: inline-block;
        padding: 6px;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 2px;
      }
    }

    .action-button-mic.active {
      animation: mic-pulsate-color 3s infinite;
    }
    button.action-button:disabled {
      opacity: 0.6;
      background: #eee;
      cursor: not-allowed;
    }

    @keyframes mic-pulsate-color {
      0% {
        background-color: #ffdcdc;
      }
      50% {
        background-color: #ff8585;
      }
      100% {
        background-color: #ffdcdc;
      }
    }

    .link-editor .button.hovered {
      width: 20px;
      height: 20px;
      display: inline-block;
      background-color: #eee;
    }

    .link-editor .button i,
    .actions i {
      background-size: contain;
      display: inline-block;
      height: 20px;
      width: 20px;
      vertical-align: -0.25em;
    }

    i.undo {
      background-image: url("${arrowCounterClockwiseIcon}");
    }

    i.redo {
      background-image: url("${arrowClockWiseIcon}");
    }

    .icon.paragraph {
      background-image: url("${textParagraphIcon}");
    }

    .icon.large-heading,
    .icon.h3 {
      background-image: url("${typeH3Icon}");
    }

    .icon.small-heading,
    .icon.h4 {
      background-image: url("${typeH4Icon}");
    }

    .icon.bullet-list,
    .icon.ul {
      background-image: url("${listUlIcon}");
    }

    .icon.numbered-list,
    .icon.ol {
      background-image: url("${listOlIcon}");
    }

    .icon.quote {
      background-image: url("${chatSquareQuouteIcon}");
    }

    .icon.code {
      background-image: url("${codeIcon}");
    }

    i.bold {
      background-image: url("${typeBoldIcon}");
    }

    i.italic {
      background-image: url("${typeItalicIcon}");
    }

    i.underline {
      background-image: url("${typeUnderlineIcon}");
    }

    i.strikethrough {
      background-image: url("${typeStrikethroughIcon}");
    }

    i.code {
      background-image: url("${codeIcon}");
    }

    i.link {
      background-image: url("${linkIcon}");
    }

    i.left-align {
      background-image: url("${textLeftIcon}");
    }

    i.center-align {
      background-image: url("${textCenterIcon}");
    }

    i.right-align {
      background-image: url("${textRightIcon}");
    }

    i.justify-align {
      background-image: url("${justifyIcon}");
    }

    i.mic {
      background-image: url("${micIcon}");
    }

    i.markdown {
      background-image: url("${markdownIcon}");
    }
  `;
}}
`;
export const lexicalGlobalCss = css`
  ${_ref2 => {
  let {
    theme
  } = _ref2;
  return css`
    .lexical {
      &.dropdown {
        z-index: 500;
        display: block;
        position: absolute;
        box-shadow: ${theme.shadows.middle};
        border-radius: 8px;
        min-width: 100px;
        min-height: 40px;
        background-color: ${theme.colors.background.primary};

        .item {
          margin: 0 8px 0 8px;
          padding: 8px;
          color: ${theme.colors.text.primary};
          cursor: pointer;
          line-height: 16px;
          font-size: 15px;
          display: flex;
          align-content: center;
          flex-direction: row;
          flex-shrink: 0;
          justify-content: space-between;
          background-color: ${theme.colors.background.primary};
          border-radius: 8px;
          border: 0;
          min-width: 268px;

          .active {
            display: flex;
            width: 20px;
            height: 20px;
            background-size: contain;
          }

          &:first-child {
            margin-top: 8px;
          }

          &:hover {
            background-color: ${theme.colors.background.secondary};
          }

          .text {
            display: flex;
            line-height: 20px;
            flex-grow: 1;
            width: 200px;
          }

          .icon {
            display: flex;
            width: 20px;
            height: 20px;
            user-select: none;
            margin-right: 12px;
            line-height: 16px;
            background-size: contain;
            ${theme.name.includes("dark") && `filter: invert(1)`};
          }

          .icon.paragraph {
            background-image: url("${textParagraphIcon}");
          }

          .icon.large-heading,
          .icon.h3 {
            background-image: url("${typeH3Icon}");
          }

          .icon.small-heading,
          .icon.h4 {
            background-image: url("${typeH4Icon}");
          }

          .icon.bullet-list,
          .icon.ul {
            background-image: url("${listUlIcon}");
          }

          .icon.numbered-list,
          .icon.ol {
            background-image: url("${listOlIcon}");
          }

          .icon.quote {
            background-image: url("${chatSquareQuouteIcon}");
          }

          .icon.code {
            background-image: url("${codeIcon}");
          }
        }
      }

      &__paragraph {
        margin-bottom: 1em;
      }
      &__quote {
        border-left: 2px solid ${theme.colors.primary.main};
        padding-left: 1rem;
        margin-bottom: 1em;
      }
      &__hashtag {
        color: ${theme.colors.primary.main};
      }
      &__textBold {
        font-weight: 600;
      }
      &__code {
        display: block;
        font-size: 0.9em;
        background-color: rgba(0, 0, 0, 0.1);
        font-family: ${theme.typography.fontFamily.code};
        padding: 0.5rem;
        border-radius: ${theme.shape.borderRadius.primary};
        margin-bottom: 1em;

        & > * {
          font-size: 0.9em;
          font-family: ${theme.typography.fontFamily.code};
        }
      }
      &__textCode {
        font-size: 0.9em;
        padding: 2px 5px;
        color: ${theme.colors.primary.main};
        border-radius: ${theme.shape.borderRadius.primary};
        font-family: ${theme.typography.fontFamily.code};
        background-color: rgba(0, 0, 0, 0.1);
      }
      &__textItalic {
        font-style: italic;
      }
      &__textStrikethrough {
        text-decoration: line-through;
      }
      &__textSubscript {
        vertical-align: sub;
      }
      &__textSuperscript {
        vertical-align: super;
      }
      &__textUnderline {
        text-decoration: underline;
      }
      &__textUnderlineStrikethrough {
        text-decoration: underline;
        position: absolute;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: ${theme.colors.text.primary};
        }
      }
    }
  `;
}}
`;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(lexicalCss, "lexicalCss", "/codebuild/output/src2003656514/src/src/components/GlobalStyle/css/lexical.css.tsx");
  reactHotLoader.register(lexicalGlobalCss, "lexicalGlobalCss", "/codebuild/output/src2003656514/src/src/components/GlobalStyle/css/lexical.css.tsx");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();